import React from "react";
import PropTypes from "prop-types";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const query = graphql`
  query BaseImageQuery {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

export const InnerImage = ({ images, fileName, alt, ...restProps }) => {
  if (fileName === "") return null;

  const image = images.edges.find((n) => n.node.relativePath.includes(fileName));
  if (!image) return null;
  if (!image.node.childImageSharp) return null; // storybook need this

  const imageData = image.node.childImageSharp.gatsbyImageData;
  return <GatsbyImage alt={alt} image={imageData} {...restProps} />;
};

InnerImage.propTypes = {
  fileName: PropTypes.string.isRequired,
  images: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  alt: PropTypes.string,
};
InnerImage.defaultProps = {
  alt: null,
};

const Image = ({ fileName, alt, ...restProps }) => {
  const { images } = useStaticQuery(query);

  return (
    images && (
      <>
        <InnerImage images={images} fileName={fileName} alt={alt} {...restProps} />
      </>
    )
  );
};

Image.propTypes = {
  fileName: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

Image.defaultProps = {
  alt: null,
};

export default Image;
