/* Validate input by provided pattern */
export const mutateValidate = (input) => {
  const m = (input.value || "").match(input.pattern);

  return {
    ...input,
    error: !(m && m[0] === input.value),
  };
};

export const mutateValidateByName = (input, matcher) => {
  if (input.name === matcher) return mutateValidate(input);

  return input;
};

/* Update value property of the object by event target value */
export const mutateByName = (item, matcher, event) => {
  return {
    ...item,
    value: item.name !== matcher ? item.value : event.target.value,
  };
};

/* Update value, placeholder and errorMessage i18n fields.  */
export const mutateWithValueAndI18n = (input, value, prefix, i18n) => {
  return {
    ...input,
    value,
    placeholder: i18n[`${prefix}Placeholder`],
    errorMessage: i18n[`${prefix}ErrorMessage`],
  };
};
