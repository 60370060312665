import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import clsx from "clsx";
import * as AllIcons from "config/CustomIcons";
import "./ContactUs.scss";
import { mutateByName, mutateValidate, mutateWithValueAndI18n } from "utils/form-utils";
import { graphql } from "gatsby";

export const fragment = graphql`
  fragment ContactUsMarkdownRemarkFragment on Frontmatter {
    namePlaceholder
    nameErrorMessage
    emailPlaceholder
    emailErrorMessage
    messagePlaceholder
    messageErrorMessage
    submitText
  }
`;

const DEFAULTS = [
  {
    type: "name",
    name: "Name",
    error: false,
    errorMessage: "Name is not specified!",
    pattern: ".+",
  },
  {
    type: "email",
    name: "Email",
    error: false,
    errorMessage: "Email is not specified correctly!",
    pattern: "\\w+([.\\-+]?\\w+)*@\\w+([.-]?\\w+)*(\\.\\w{2,10})+",
  },
  {
    type: "message",
    name: "Message",
    error: false,
    errorMessage: "Message is not specified!",
    pattern: ".+",
  },
];

/* NOTE: frontmatter expect contect from content/ContactUs.*.md files. We have one localization file for ContactUs and SendEmailForm. */
const Contact = ({ frontmatter, isError, isSuccess, name, email, message }) => {
  frontmatter = frontmatter || {};

  const [inputName, inputEmail, inputMessage] = DEFAULTS;
  const inputs = [
    mutateWithValueAndI18n(inputName, name, "name", frontmatter),
    mutateWithValueAndI18n(inputEmail, email, "email", frontmatter),
    mutateWithValueAndI18n(inputMessage, message, "message", frontmatter),
  ].map((input) => (input.value ? mutateValidate(input) : input));

  const [inputsState, setInputsState] = useState(inputs);
  const [hasError, setError] = useState(isError);
  const [hasSuccess, setSuccess] = useState(isSuccess);

  /* Reset the form state to initial if it has all fields empty  */
  useEffect(() => {
    const timer = setTimeout(() => {
      const isEmpty = inputsState.every((input) => (input.value || "").length === 0);
      if (isEmpty) {
        setInputsState(
          inputsState.map((input) => ({
            ...input,
            error: false,
          })),
        );
      }
      // reset states
      setError(isError);
      setSuccess(isSuccess);
    }, 3500);

    return () => clearTimeout(timer);
  }, [inputsState, hasSuccess, hasError, isError, isSuccess]);

  /* on any change re-validate the value's. */
  const handleOnChange = (event, input) => {
    event.preventDefault();

    const updated = inputsState
      .map((item) => mutateByName(item, input.name, event))
      .map((item) => mutateValidate(item));

    setInputsState(updated);
  };

  /* empty form field on success  */
  const handleOnSuccess = () => {
    setInputsState(inputsState.map((input) => ({ ...input, value: "", error: false })));

    setSuccess(true);
  };

  /* Display error to User. */
  const handleOnFailure = (error) => {
    // eslint-disable-next-line no-console
    console.dir(error?.response?.data);

    setError(true);
  };

  /* POST form data to Netlify */
  const handleOnSubmit = (event) => {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      // console.log("validation", inputsState);
      setInputsState(inputsState.map((input) => mutateValidate(input)));
    } else {
      // make submit to Firebase Function
      const data = inputsState.reduce((acc, input) => ({ ...acc, [input.type]: input.value }), {
        phone: "+46000000000" /* fake phone */,
        lang: "en",
      });

      /* Defined in .env.production OR .env.development. */
      const api = `${process.env.GATSBY_SEND_EMAIL_API_URL}`;

      axios
        .post(api, data)
        .then(() => handleOnSuccess())
        .catch((err) => handleOnFailure(err));
    }
  };

  const basicName = inputsState[0];
  const basicEmail = inputsState[1];
  const basicMessage = inputsState[2];

  return (
    <Form
      className="form-footer-email"
      name="contactus"
      noValidate
      onSubmit={handleOnSubmit}
      method="POST"
    >
      <h6 className="text-left">Contact Us</h6>
      <Form.Group controlId="formBasicName">
        <Form.Control
          type={basicName.type}
          name={basicName.type}
          required={true}
          placeholder={basicName.placeholder}
          isInvalid={basicName.error}
          pattern={basicName.pattern}
          value={basicName.value}
          onChange={(event) => handleOnChange(event, basicName)}
        />
        <Form.Control.Feedback type="invalid" className="text-left">
          {basicName.errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Control
          type={basicEmail.type}
          name={basicEmail.type}
          required={true}
          isInvalid={basicEmail.error}
          placeholder={basicEmail.placeholder}
          pattern={basicEmail.pattern}
          value={basicEmail.value}
          onChange={(event) => handleOnChange(event, basicEmail)}
        />
        <Form.Control.Feedback type="invalid" className="text-left">
          {basicEmail.errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBasicMessage">
        <Form.Control
          as="textarea"
          rows={2}
          name={basicMessage.type}
          required={true}
          isInvalid={basicMessage.error}
          placeholder={basicMessage.placeholder}
          pattern={basicMessage.pattern}
          value={basicMessage.value}
          onChange={(event) => handleOnChange(event, basicMessage)}
        />
        <Form.Control.Feedback type="invalid" className="text-left">
          {basicMessage.errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="text-right">
        <Button variant="secondary" type="submit" className="text-nowrap">
          {frontmatter.submitText}
        </Button>
      </div>
      <div
        className={clsx("form-state-wrapper", {
          "d-block": hasSuccess || hasError,
          "d-none": !hasError && !hasSuccess,
        })}
      >
        <div
          className={clsx("form-state", {
            "d-block": hasError,
            "d-none": !hasError,
          })}
        >
          <AllIcons.BanIcon className="form-errorIcon" />
        </div>
        <div
          className={clsx("form-state", {
            "d-block": hasSuccess,
            "d-none": !hasSuccess,
          })}
        >
          <AllIcons.CircleCheckIcon className="form-successIcon" />
        </div>
      </div>
    </Form>
  );
};

Contact.defaultProps = {
  name: "",
  email: "",
  message: "",
  isError: false,
  isSuccess: false,
  frontmatter: null,
};

Contact.propTypes = {
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  name: PropTypes.string,
  email: PropTypes.string,
  message: PropTypes.string,
  frontmatter: PropTypes.shape({
    namePlaceholder: PropTypes.string,
    nameErrorMessage: PropTypes.string,
    emailPlaceholder: PropTypes.string,
    emailErrorMessage: PropTypes.string,
    messagePlaceholder: PropTypes.string,
    messageErrorMessage: PropTypes.string,
    submitText: PropTypes.string,
  }),
};

export default Contact;
