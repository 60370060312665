import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import clsx from "clsx";

export const fragment = graphql`
  fragment ParagraphFragment on Paragraph {
    h1
    h2
    h3
    h4
    h5
    h6
    text
  }
`;

export default function RenderParagraph({ p }) {
  return (
    <div className={clsx("custom-paragraph")}>
      {p && p.h1 && <h1>{p.h1}</h1>}
      {p && p.h2 && <h2>{p.h2}</h2>}
      {p && p.h3 && <h3>{p.h3}</h3>}
      {p && p.h4 && <h4>{p.h4}</h4>}
      {p && p.h5 && <h5>{p.h5}</h5>}
      {p && p.h6 && <h6>{p.h6}</h6>}
      {p && p.text && (
        <>
          <ReactMarkdown>{p.text}</ReactMarkdown>
        </>
      )}
    </div>
  );
}

RenderParagraph.propTypes = {
  p: PropTypes.shape({
    h1: PropTypes.string,
    h2: PropTypes.string,
    h3: PropTypes.string,
    h4: PropTypes.string,
    h5: PropTypes.string,
    h6: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};
