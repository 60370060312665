import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Image from "components/Image";

const Client = ({ imageFileName, href, className }) => {
  const imgPart = (
    <Image
      className={clsx("img-fluid", "d-block", "mx-auto", className)}
      fileName={imageFileName}
      alt={imageFileName}
    />
  );

  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {imgPart}
      </a>
    );
  }

  return imgPart;
};

Client.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  href: PropTypes.string,
  className: PropTypes.string,
};

Client.defaultProps = {
  href: null,
  className: null,
};

export default Client;
