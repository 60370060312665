module.exports = {
  title: "norlandservice.se",
  siteUrl: "https://norlandservice.se",
  keywords: [
    "måleriarbeten",
    "allt inom renovering",
    "städservice",
    "byggstäda",
    "flyttstädningen",
    "fönsterputs",
  ],
  description: "Norlandservice Your Perfect Painting Service",
  author: "artfulbits.se",
  trackingId: "UA-198755035-1",
  defaultLang: "en",
  langTextMap: {
    en: "English",
    sv: "Svenska",
  },
  extraPages: ["/privacy", "/terms", "/inspection"],
  landingsPages: [
    "/landings/bundle",
    "/landings/cleaning",
    "/landings/renovation",
    "/landings/painting",
    "/landings/rot",
  ],
};
