import makeFAIcon from "utils/makeFAIcon";

import {
  faPaintBrush,
  faPaintRoller,
  faBroom,
  faMedal,
  faHandHoldingUsd,
  faCheckCircle,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

export const PaintBrush = makeFAIcon(faPaintBrush);
export const PaintRoller = makeFAIcon(faPaintRoller);
export const Broom = makeFAIcon(faBroom);
export const CircleCheckIcon = makeFAIcon(faCheckCircle);
export const BanIcon = makeFAIcon(faBan);
export const Medal = makeFAIcon(faMedal);
export const HandHoldingUsd = makeFAIcon(faHandHoldingUsd);
