import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Card } from "react-bootstrap";
import "./VideoCard.scss";

/**
 * Ref:
 * - https://startbootstrap.com/snippets/video-header
 * */
const VideoCard = ({
  className,
  videoFileName,
  videoPoster,
  videoAlt,
  header,
  subheader,
  extraInfo,
  children,
}) => {
  return (
    <header className={clsx("video-background", className)}>
      <video
        id="bannerVideo"
        autoPlay
        muted
        loop
        playsInline={true}
        title={videoAlt || header || subheader}
        poster={videoPoster}
      >
        <source src={videoFileName} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      {/* apply overlay tone color */}
      <div className="overlay-primary-accent" />
      {/* place controls on top of image */}
      <Card className="content-overlay px-2 text-light">
        <h1 className="intro-heading text-uppercase text-center">{header}</h1>
        {subheader && <h2 className="intro-lead-in text-center">{subheader}</h2>}
        {extraInfo}
        {children}
      </Card>
    </header>
  );
};

VideoCard.propTypes = {
  className: PropTypes.string,
  videoFileName: PropTypes.string,
  videoPoster: PropTypes.string,
  videoAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  extraInfo: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

VideoCard.defaultProps = {
  className: null,
  videoFileName: null,
  videoPoster: null,
  videoAlt: null,
  header: "",
  subheader: "",
  extraInfo: null,
  children: null,
};

export default VideoCard;
