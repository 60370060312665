import React, { useState, useCallback } from "react";
import { navigate, graphql } from "gatsby";
import PropTypes from "prop-types";
import clsx from "clsx";
import getBaseUrl from "utils/getBaseUrl";
import { Navbar, Container, Nav } from "react-bootstrap";
import useWindowOnScroll from "hooks/useWindowOnScroll";
import useSmoothScrollTo from "hooks/useSmoothScrollTo";
import Icon from "components/Icon";
import NavItem from "components/NavItem";
import Logo from "../../../content/assets/images/logos/LOGO.svg";

import "./Navbar.scss";
import { defaultLang } from "config/site";

export const fragment = graphql`
  fragment NavbarMarkdownRemarkFragment on Frontmatter {
    title
    brand
    menuText
  }
`;

const MyNavbar = ({ anchors, langKey, frontmatter, children }) => {
  const { brand, menuText } = frontmatter;

  const handleScrollToTop = useSmoothScrollTo(0);
  const [expanded, setExpanded] = useState(false);
  const [shrink, setShrink] = useState(false);

  const toggleMenu = useCallback(() => setExpanded(!expanded), [expanded]);
  const closeMenu = useCallback(() => setExpanded(false), []);
  const handleBrandClick = useCallback(() => {
    closeMenu();
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop !== 0) {
      handleScrollToTop();
    } else {
      // noinspection JSIgnoredPromiseFromCall
      navigate(getBaseUrl(defaultLang, langKey));
    }
  }, [closeMenu, handleScrollToTop]);
  const handleWindowScroll = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setShrink(scrollTop > 100);
  }, []);
  useWindowOnScroll(handleWindowScroll);

  return (
    <Navbar
      className={clsx("navbar-root", { "navbar-shrink": shrink })}
      expand="lg"
      fixed="top"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand className="cursor-pointer" onClick={handleBrandClick}>
          <Logo className="img-logo" alt={brand} />
          {brand}
        </Navbar.Brand>
        <Navbar.Toggle onClick={toggleMenu} aria-label="Toggle navigation">
          {menuText}
          <Icon iconName="BarsIcon" />
        </Navbar.Toggle>
        <Navbar.Collapse>
          <Nav className="text-uppercase ml-auto navbar-anchors">
            {anchors.map((anchor) => (
              <NavItem key={anchor} to={anchor} onClick={closeMenu} />
            ))}
          </Nav>
          {children}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

MyNavbar.propTypes = {
  anchors: PropTypes.arrayOf(PropTypes.string),
  langKey: PropTypes.string,
  frontmatter: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

MyNavbar.defaultProps = {
  anchors: [],
  langKey: "en",
  frontmatter: {},
  children: null,
};

export default MyNavbar;
