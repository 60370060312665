import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import * as SocialIcons from "components/SocialIcons";
import { extraPages, landingsPages } from "config/site";
import ContactUs from "../Forms/ContactUs";
import Logo from "../../../content/assets/images/logos/LOGO.svg";
import "./Footer.scss";

/** Fragment correspond file structure: content/Footer.*.md */
export const fragment = graphql`
  fragment FooterMarkdownRemarkFragment on Frontmatter {
    title
    copyright
    social {
      facebook
      instagram
    }
  }
`;

const Footer = ({ frontmatter, navigationNode, contactUsNode }) => {
  if (!frontmatter) return null;

  const {
    copyright,
    social: { facebook, github, linkedin, medium, twitter, instagram, youtube },
  } = frontmatter;

  const [serviceNode, extrasNode] = navigationNode.paragraphs;
  const { navigation } = navigationNode;

  return (
    <footer className="page-section footer py-6 pt-4 pb-2">
      <Container className="container-fluid">
        <Row className="text-center">
          <Col lg={4} className="text-lg-left pt-4">
            <Logo className="img-logo" />
            <div className="copyright">{copyright}</div>
            <div className="copyright">
              Developed By: <a href="https://artfulbits.se">ArtfulBits IT AB</a>
            </div>
            <div className="social-icons">
              {twitter && <SocialIcons.Twitter userName={twitter} />}
              {facebook && <SocialIcons.Facebook userName={facebook} />}
              {instagram && <SocialIcons.Instagram userName={instagram} />}
              {youtube && <SocialIcons.Youtube userName={youtube} />}
              {linkedin && <SocialIcons.Linkedin userName={linkedin} />}
              {github && <SocialIcons.Github userName={github} />}
              {medium && <SocialIcons.Medium userName={medium} />}
            </div>
          </Col>
          <Col lg={4} className="text-md-left col-lg-3 pt-4">
            <Row>
              <Col lg={6} md={6}>
                <ul className="footer-menu-items">
                  <li>
                    <h6>{extrasNode.h6}</h6>
                  </li>
                  {extraPages
                    .map((page) => navigation.find((n) => n.path.endsWith(page)))
                    .map((page) => (
                      <li key={page.title}>
                        <a className="mr-3 link-text" href={page.path}>
                          {page.title}
                        </a>
                      </li>
                    ))}
                </ul>
              </Col>
              <Col lg={6} md={6}>
                <ul className="footer-menu-items">
                  <li>
                    <h6>{serviceNode.h6}</h6>
                  </li>
                  {landingsPages
                    .map((page) => navigation.find((n) => n.path.endsWith(page)))
                    .map((page) => (
                      <li key={page.title}>
                        <Link to={page.path} className="link-text">
                          {page.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </Col>
            </Row>
          </Col>
          <Col lg={4} className="text-lg-center pb-2 py-4">
            <ContactUs frontmatter={contactUsNode} />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
  navigationNode: PropTypes.object,
  contactUsNode: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
  navigationNode: null,
  contactUsNode: null,
};

export default Footer;
