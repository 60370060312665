import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Button, Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import ImageCard from "components/ImageCard";
import VideoCard from "components/VideoCard";
import useSmoothScrollTo from "hooks/useSmoothScrollTo";
import VideoBg from "content/assets/videos/brush-720p.webm";
import VideoThumb from "content/assets/videos/brush-720p-thumbnail-0001.webp";
import "./Top.scss";

export const fragment = graphql`
  fragment TopMarkdownRemarkFragment on Frontmatter {
    header
    subheader
    imageFileName
    videoFileName
    jumpToAnchor
    jumpToAnchorText
    jumpToAnchorSecondary
    jumpToAnchorTextSecondary
    paragraphs {
      ...ParagraphFragment
    }
  }
`;

const primaryButton = (text, onClick) => (
  <Button
    size="xl"
    variant="primary"
    className="cta-btn text-uppercase my-2 mr-2 text-nowrap"
    onClick={onClick}
  >
    {text}
  </Button>
);

const secondaryButton = (text, onClick) => (
  <Button
    size="xl"
    variant="secondary"
    className="cta-btn text-uppercase my-2 mr-2 text-nowrap"
    onClick={onClick}
  >
    {text}
  </Button>
);

const Top = ({ frontmatter, video, poster }) => {
  if (!frontmatter) return null;

  const { header, subheader, paragraphs } = frontmatter;
  const { imageFileName, videoFileName } = frontmatter;
  const { jumpToAnchor, jumpToAnchorText } = frontmatter;
  const { jumpToAnchorSecondary, jumpToAnchorTextSecondary } = frontmatter;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollToPrimary = useSmoothScrollTo(jumpToAnchor);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollToSecondary = useSmoothScrollTo(jumpToAnchorSecondary);

  if (videoFileName) {
    return (
      <VideoCard
        videoFileName={video || VideoBg}
        header={header}
        subheader={subheader}
        videoPoster={poster || VideoThumb}
      >
        <Row className="px-4" lg={12}>
          <Col lg={4} />
          <Col lg={4} className="lead-custom text-center">
            {paragraphs && paragraphs.map((p) => <p key={uuidv4()}>{p.text}</p>)}
            {jumpToAnchor && jumpToAnchorText && primaryButton(jumpToAnchorText, scrollToPrimary)}
            {jumpToAnchorSecondary &&
              jumpToAnchorTextSecondary &&
              secondaryButton(jumpToAnchorTextSecondary, scrollToSecondary)}
          </Col>
          <Col lg={4} />
        </Row>
      </VideoCard>
    );
  }

  return (
    <ImageCard imageFileName={imageFileName} header={header} subheader={subheader}>
      {jumpToAnchor && jumpToAnchorText && secondaryButton(jumpToAnchorText, scrollToPrimary)}
    </ImageCard>
  );
};

Top.propTypes = {
  frontmatter: PropTypes.object,
  video: PropTypes.node,
  poster: PropTypes.node,
};

Top.defaultProps = {
  frontmatter: null,
  video: null,
  poster: null,
};

export default Top;
