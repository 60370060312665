import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { Nav } from "react-bootstrap";
import LanguageSelector from "components/LanguageSelector";

/** Fragment correspond file structure: content/Footer.*.md */
export const fragment = graphql`
  fragment LandingsNavigationMarkdownRemarkFragment on Frontmatter {
    paragraphs {
      ...ParagraphFragment
    }
    navigation {
      title
      path
    }
  }
`;

const AlternativeNavigation = ({ context, frontmatter }) => {
  if (!frontmatter) return null;

  const { navigation } = frontmatter;

  return (
    <>
      {/* show navigation to other landings */}
      {context.landingsPages
        .map((page) => navigation.find((n) => n.path.endsWith(page)))
        .map((page) => (
          <Nav.Item className="text-uppercase" key={page.title}>
            <Link className="nav-link cursor-pointer" to={page.path}>
              {page.title}
            </Link>
          </Nav.Item>
        ))}
      {/* compose language selector if we have more than one language */}
      {context.langTextMap && Object.keys(context.langTextMap).length > 1 && (
        <LanguageSelector {...context} />
      )}
    </>
  );
};

AlternativeNavigation.defaultProps = {
  context: { landingsPages: [], langTextMap: {} },
  frontmatter: {},
};
AlternativeNavigation.propTypes = {
  context: PropTypes.shape({
    landingsPages: PropTypes.arrayOf(PropTypes.any),
    langTextMap: PropTypes.object,
  }),
  frontmatter: PropTypes.object,
};

export default AlternativeNavigation;
